import React, { useState } from 'react'
import TypeOfCargo from './Blocks/TypeOfCargo'
import RemoteInformation from './Blocks/RemoteInformation'
import LoadState from './Blocks/LoadState'
import './MapWarehouseBid.scss';
import { LoadStateType } from '../../../store/bids';
import Modal from '../../../components/Modal';
import { MapWarehouseBidTypeBase } from '.';

type MapWarehouseBidType = MapWarehouseBidTypeBase &  {
  loadState: LoadStateType;
  setLoadState: (loadState: LoadStateType) => void;
}

const MapWarehouseBid: React.FC<MapWarehouseBidType> = ({
  loadState, setLoadState, location, 
  isOpenModal, onCloseModal
}) => {
  const [tab, setTab] = useState<'type' | 'information'>('type')
  
  const nextForm = () => {
    setTab('information')
    setLoadState(null)
  }

  const onCloseModalHandler = () => {
    setLoadState(null)
    onCloseModal()
  }

  return (
    <Modal 
      className={`${loadState ? '' : 'map-point__modal-bid'}`} 
      isOpen={isOpenModal} 
      onClose={onCloseModalHandler}
    >      
      <div className="warehouse-bid">
        {loadState ? (
          <LoadState state={loadState} onClick={nextForm} />
        ) : (
          <>
            <span className="warehouse-bid__title">Заявка на склад</span>
            {tab === 'type' && (
              <TypeOfCargo location={location} />
            )}
            {tab === 'information' && (
              <RemoteInformation />
            )}
          </>
        )}
      </div>
    </Modal>
  )
}

export default MapWarehouseBid