import React, { useState } from 'react';
import Button from '../Button';
import SelectRegion from '../SelectRegion';
import { ReactComponent as RegionPin } from './mappin.svg';
import { ReactComponent as Clear } from './clear.svg';
import { ReactComponent as Menu } from './menu.svg';
import './Header.scss';
import Modal from '../Modal';

type HeaderType = {
  region: string;
  page: 'main' | 'map';
  setPage: (page: 'main' | 'map') => void;
};

type ModalType = 'region' | 'signin'

const SIGNIN_WAREHOUSE_IFRAME_LINK = 'https://forms.yandex.ru/surveys/13456900.f14a1c433aa141097e54314ef08167744eefce85/?iframe=1'
const SIGNIN_WAREHOUSE_IFRAME_NAME = 'ya-form-13456900.f14a1c433aa141097e54314ef08167744eefce85'

const Header: React.FC<HeaderType> = ({ region, page, setPage }) => {
  const [modalIsOpen, setIsOpen] = useState<ModalType>(undefined);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const openModal = (key: ModalType) => setIsOpen(key);
  const closeModal = () => setIsOpen(undefined);

  return (
    <header className={`main-header ${page}`}>
      <div className="container">
        <div className="main-header__wrap">
          <div className="main-header__logo">
          </div>
          <button type="button" onClick={() => openModal('region')} className="main-header__region">
            <RegionPin />
            <span>{region}</span>
          </button>
          <button className="main-header__menu" onClick={() => setMenuIsOpen(!menuIsOpen)}>
            {menuIsOpen ? <Clear /> : <Menu />}
          </button>
          <nav className={`main-header__nav ${menuIsOpen ? 'open' : ''}`}>
            <a href="#"
              onClick={(e) => {
                e.preventDefault();
                setMenuIsOpen(false);
                setPage('main');
              }}
              className={`${page === 'main' ? 'active' : ''}`}
            >
              Что за сервис
            </a>
            <a href="#"
              onClick={(e) => {
                e.preventDefault();
                setMenuIsOpen(false);
                setPage('map');
              }}
              className={`${page === 'map' ? 'active' : ''}`}
            >
              Наши склады
            </a>
            <Button variant="outline" className="main-header__button" onClick={() => openModal('signin')}>
              Зарегистрировать склад
            </Button>
          </nav>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen === 'region'}
        onClose={closeModal}
      >
        <h3 className="select-region-content__title">Укажите свой регион</h3>
        <SelectRegion onFetchRegion={closeModal} />
      </Modal>
      <Modal
        isOpen={modalIsOpen === 'signin'}
        onClose={closeModal}
        className="main-header__signin-modal"
      >
        <iframe title="Зарегистрировать склад" src={SIGNIN_WAREHOUSE_IFRAME_LINK} frameBorder="0" name={SIGNIN_WAREHOUSE_IFRAME_NAME} width="100%" height="100%" />
      </Modal>
    </header>
  );
};

export default Header;
