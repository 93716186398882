import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import './MapButton.scss';
import ReactTooltip from 'react-tooltip';

type MapButtonType = {
  id: string;
  className?: string;
  onClick?: () => void;
  tip?: string;
  tipPlace?: 'left' | 'right';
  tipShow?: boolean
};

const MapButton: React.FC<MapButtonType> = ({ className, children, tip, tipPlace = 'left', onClick, id, tipShow = false }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const getButtonClass = ():string => classnames('map-button', className);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    if (!buttonRef.current) return;
    if (tipShow) {
      ReactTooltip.show(buttonRef.current);
    }
  }, [buttonRef, tipShow]);

  return (
    <>
      <ReactTooltip globalEventOff={isMobile ? 'click' : undefined} id={id} backgroundColor="#302F2D" effect="solid" className="app-tooltip" />
      <button 
        type="button"
        className={getButtonClass()}
        onClick={onClick}
        data-tip={tip}
        data-place={tipPlace}
        data-for={id}
        ref={buttonRef}
      >
        {children}
      </button>
    </>
  );
};

export default MapButton;
