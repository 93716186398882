import React, { useState } from 'react';
import ModalSelectRegion from '../../components/ModalSelectRegion';
import Filters from './Filters';
import MapPoint from './MapPoint';
import Map from './Map';
import './MapPage.scss';
import { MapItemType, RegionType } from '../../store/regions';

type MapPageType = {
  region: RegionType
}

const MapPage: React.FC<MapPageType> = ({ region }) => {
  const [activeItem, setActiveItem] = useState<MapItemType | null>(null);
  const [
    geolocationCoordinate, 
    setGeolocationCoordinate
  ] = useState<number[]>([]);

  const closeMapPointHandler = (): void => setActiveItem(null);

  if (Object.keys(region).length === 0) return (
    <div className="map-page">
      <main className="map-page__main">
        <ModalSelectRegion />
      </main>
    </div>
  );

  return (
    <div className="map-page">
      <main className="map-page__main">
        <Filters isActive={!!activeItem} />
        <div className="map-page__block">
          <MapPoint 
            item={activeItem}
            active={!!activeItem}
            onClose={closeMapPointHandler}
            geolocationCoordinate={geolocationCoordinate}
          />
        </div>
        <Map
          regionCenter={region.center}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          geolocationCoordinate={geolocationCoordinate}
          setGeolocationCoordinate={setGeolocationCoordinate}
        />
      </main>
    </div>
  );
};

export default MapPage;
