import React from 'react'
import { useSelector } from 'react-redux'
import { getProposal } from '../../../../store/bids'

const REQUEST_WAREHOUSE_IFRAME_LINK = 'https://forms.yandex.ru/u/62d55b3d1dd4b21272a8f82c/'
const REQUEST_WAREHOUSE_IFRAME_NAME = 'ya-form-62d55b3d1dd4b21272a8f82c'

const RemoteInformation: React.FC = () => {
  const search_params = new URLSearchParams(window.location.search)
  const utm_source = search_params.get('utm_source')
  const proposal = useSelector(getProposal)
  let iframe_link = REQUEST_WAREHOUSE_IFRAME_LINK
    + `?iframe=1`
    + `&answer_short_text_51563741=${proposal.location_name}`
    + `&answer_non_profile_email_51563776=${proposal.location_email}`
  if (utm_source) iframe_link += `&answer_short_text_51563740=${utm_source}`
  if (proposal.pallet_spaces) iframe_link += `&answer_short_text_51563471=${proposal.pallet_spaces}`
  if (proposal.width) iframe_link += `&answer_short_text_51563476=${proposal.width}`
  if (proposal.height) iframe_link += `&answer_short_text_51563478=${proposal.height}`
  if (proposal.length) iframe_link += `&answer_short_text_51563479=${proposal.length}`
  return (
    <>
      <iframe title="Зарегистрировать склад" src={iframe_link} frameBorder="0" name={REQUEST_WAREHOUSE_IFRAME_NAME} width="100%" height="100%" />
    </>
  )
}

export default RemoteInformation