import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadStateType, ProposalAddResponseType } from './types';

export const initialState = {
  loadState: null as LoadStateType,
  proporsal: undefined as ProposalAddResponseType | undefined,
  isLoadingPPC: false
};
 
const bids = createSlice({
  name: 'bids',
  initialState,
  reducers: {
    setLoadState: (state, action: PayloadAction<LoadStateType>) => {
      state.loadState = action.payload;
    },
    setProporsal: (state, action: PayloadAction<ProposalAddResponseType>) => {
      state.proporsal = action.payload;
    },
    setLoadingPPC: (state, action: PayloadAction<boolean>) => {
      state.isLoadingPPC = action.payload;
    }
  },
});

export const { reducer, actions } = bids;
export const { setLoadState, setProporsal, setLoadingPPC } = actions;
export default reducer;