import { createSelector } from 'reselect';
import { RootState, Selector } from '../rootTypes';
import { InitialStateType, LoadStateType, ProposalAddResponseType } from './types';

export const getBidsModule = (state: RootState): InitialStateType => 
  state.RootReducer.bids;

export const getLoadState: Selector<LoadStateType> = 
  createSelector(
    getBidsModule, 
    bids => bids.loadState
  );

export const getProposal: Selector<ProposalAddResponseType> = 
  createSelector(
    getBidsModule, 
    bids => bids.proporsal
  );

export const getLoadingPPC: Selector<boolean> = 
  createSelector(
    getBidsModule, 
    bids => bids.isLoadingPPC
  );