import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MainPage from '../pages/MainPage';
import MapPage from '../pages/MapPage';
import AppHeader from '../components/Header';
import { getActiveCity } from '../store/cities';
import './App.scss';
import { fetchRegion, getRegion } from '../store/regions';
import { useAppDispatch } from '../store/rootTypes';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const activeCity = useSelector(getActiveCity);
  const region = useSelector(getRegion);
  const [page, setPage] = useState<'main' | 'map'>('main');
  const fetchRegionHandler = () => dispatch(fetchRegion(region.id || activeCity.id));

  const setPageHandler = (e: 'main' | 'map') => {
    if (e === 'map' && Object.keys(region).length === 0) {
      fetchRegionHandler();
    }

    setPage(e);
  };

  return (
    <div className="App">
      <AppHeader region={region.name || activeCity.name || ''} page={page} setPage={setPageHandler} />
      <div className="App__main">
        {page === 'main' ? (
          <MainPage />
        ) : (
          <MapPage />
        )}
      </div>
    </div>
  );
};

export default App;
