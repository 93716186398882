import { AxiosResponse } from 'axios';
import { axiosPost } from '../../helpers/api';
import { getFormData } from '../../helpers/jsonToFormDate';
import { ProposalAddRequestType, ProposalAddResponseType, ProposalCompanyAddRequestType, ProposalCompanyResponseType } from './types';

export const apiPostProposalAdd = async (request: ProposalAddRequestType) => {
  try {
    const res: AxiosResponse<ProposalAddResponseType> = await axiosPost('v1/proposal/add', getFormData(request));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiPostProposalCompanyAdd = async (request: ProposalCompanyAddRequestType) => {
  try {
    const res: AxiosResponse<ProposalCompanyResponseType> = await axiosPost('v1/proposal-company/add', getFormData(request));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};