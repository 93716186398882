import React, { useState } from 'react';
import { CitiesType, CityItemType } from '../../store/cities';
import { ReactComponent as Clear } from './clear.svg';
import './SelectRegion.scss';

type SelectRegionType = {
  cities: CitiesType
  isLoadingCities: boolean
  isLoadingRegion: boolean
  fetchRegionHandler: (id: number) => void
  fetchCitiesHandler: (query?: string) => void
};

const SelectRegion: React.FC<SelectRegionType> = ({ 
  cities, isLoadingCities, isLoadingRegion, 
  fetchRegionHandler, fetchCitiesHandler
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [isShowRegionList, setIsShowRegionList] = useState<boolean>(true);

  const inputHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
    if (!isShowRegionList) {
      setIsShowRegionList(true);
    }
    fetchCitiesHandler(e.currentTarget.value);
  };

  const clickRegionHandler = (cityItem: CityItemType) => {
    setSearchText(cityItem.name);
    setIsShowRegionList(false);
    fetchRegionHandler(cityItem.id);
  }; 

  const clearHandler = () => {
    setSearchText('');
    fetchCitiesHandler();
    setTimeout(() => {
      setIsShowRegionList(true);
    }, 100);
  };
  
  return (
    <div className="select-region">
      <div className="select-region__group">
        <input type="text" className="select-region__input" value={searchText} onInput={inputHandler} />
        {searchText.length > 0 && (
          <button 
            type="button"
            className="select-region__clear"
            aria-label="Очистить инпут"
            onClick={clearHandler}
            disabled={searchText.length === 0}
          >
            <Clear />
          </button>
        )}
      </div>
      {isShowRegionList && (
        <div className="select-region__wrap">
          {cities.length === 0 && !isLoadingCities && (
            <p className="select-region__empty">
              <span role="img" aria-label="smile">🙃️</span> Такой регион не найден
            </p>
          )}
          {isLoadingCities ? (
            <p>Загрузка...</p>
          ) : (
            <ul className="select-region__list">
              {cities.map(item => (
                <li className="select-region__item" key={item.id}>
                  <button type="button" onClick={() => clickRegionHandler(item)}>{item.name}</button>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectRegion;
