import React, { useState } from 'react'
import { postProposalAdd, ProposalAddRequestType } from '../../../../store/bids'
import { useAppDispatch } from '../../../../store/rootTypes'
import FormActions from './FormActions'
import FormBlock from './FormBlock'
import FormGroup from './FormGroup'

type TypeOfCargoType = {
  location: number;
}

const getDefaultState = (location: number): ProposalAddRequestType => ({
  location,
  cargo_type: 'pallet',
  pallet_spaces: undefined,
  width: undefined,
  height: undefined,
  length: undefined
})

type DefaultErrorType = {
  [key: string]: string
}

const defaultError: DefaultErrorType = {
  pallet_spaces: '',
  width: '',
  height: '',
  length: ''
}

const TypeOfCargo: React.FC<TypeOfCargoType> = ({ 
  location 
}) => {
  const dispatch = useAppDispatch()
  const [state, setState] = useState(getDefaultState(location))

  const [errors, setErrors] = useState<DefaultErrorType>({
    ...defaultError
  })

  const changeState = (key: keyof ProposalAddRequestType, value: unknown) => {
    setState({
      ...state,
      [key]: value
    })
  }

  const changeCargoTypes = (type: 'pallet' | 'oversize') => {
    setErrors({
      ...defaultError
    })
    setState({
      ...getDefaultState(location),
      'cargo_type': type
    })
  }

  const changeInput = (key: keyof ProposalAddRequestType, value: string) => {
    setErrors({
      ...errors,
      [key]: defaultError[key]
    })

    changeState(key, value && !isNaN(Number(value)) ? Number(value) : value)
  }

  const onSubmit = () => {
    const checkKeys = state.cargo_type === 'pallet' 
      ? ['pallet_spaces'] 
      : ['width', 'height', 'length']

    const error: DefaultErrorType = {}

    checkKeys.forEach(key => {
      if (isNaN(Number(state[key]))) error[key] = 'Должно быть число'
      if (!state[key]) error[key] = 'Обязательное поле'
    })

    setErrors(error)

    if (Object.values(error).some(error => error.length > 0)) return

    dispatch(postProposalAdd(state));
  }

  return (
    <>
      <FormGroup title="Тип груза">
        <FormBlock label="Выберите нужный вариант">
          <select 
            className="warehouse-bid__group-select" 
            value={state.cargo_type} 
            onChange={(e) => changeCargoTypes(e.target.value as 'pallet' | 'oversize')}
          >
            <option value="pallet">Паллеты</option>
            <option value="oversize">Негабаритный груз</option>
          </select>
        </FormBlock>
        {state.cargo_type === 'pallet' && (
          <FormBlock label="Количество паллетомест" errorText={errors.pallet_spaces}>
            <input 
              className="warehouse-bid__group-input" 
              placeholder="Укажите количество"  
              value={state.pallet_spaces}
              onChange={(e) => changeInput('pallet_spaces', e.currentTarget.value)}
            />
          </FormBlock>
        )}
        {state.cargo_type === 'oversize' && (
          <FormBlock>
            <div className="warehouse-bid__group-inputs">
              <input 
                type="text" 
                placeholder="Ширина, см" 
                className="w-30" 
                value={state.width} 
                onChange={(e) => changeInput('width', e.currentTarget.value)} 
              />
              <input 
                type="text" 
                placeholder="Высота, см" 
                className="w-30" 
                value={state.height} 
                onChange={(e) => changeInput('height', e.currentTarget.value)} 
              />
              <input 
                type="text" 
                placeholder="Длина, см" 
                className="w-30" 
                value={state.length} 
                onChange={(e) => changeInput('length', e.currentTarget.value)} 
              />
            </div>
          </FormBlock>
        )}
      </FormGroup>
      <FormActions
        step={1}
        stepMax={2}
        buttonText="Отправить запрос"
        onClick={onSubmit}
      />
    </>
  )
}

export default TypeOfCargo