import React from 'react'
import { ReactComponent as Loading } from './loading.svg';
import { ReactComponent as Empty } from './empty.svg';
import { ReactComponent as Success } from './success.svg';
import Button from '../../../../../components/Button';
import './LoadState.scss'
import { LoadStateType as MainLoadStateType } from '../../../../../store/bids';

type LoadStateType = {
  state: MainLoadStateType
  onClick: () => void
}

const data = {
  loading: {
    icon: <Loading />,
    title: 'Проверка складов',
    text: 'Подождите, пожалуйста, мы проверяем наличие свободных мест на складе для вашего груза'
  },
  empty: {
    icon: <Empty />,
    title: 'Нет свободных мест',
    text: 'К сожалению, на данный момент на складе отсутствуют свободные места для вашего груза. Попробуйте позднее'
  },
  success: {
    icon: <Success />,
    title: 'Есть свободные места',
    text: 'Спасибо за ожидание! Мы нашли свободные места для размещения вашего груза на складе!'
  },
  booked: {
    icon: <Success />,
    title: 'Успешно забронировано',
    text: 'Ожидайте подтверждение от склада'
  }
}

const LoadState: React.FC<LoadStateType> = ({ state, onClick }) => {
  if (state === null) return 

  return (
    <div className="load-state">
      <div className="load-state__icon">
        {data[state].icon}
      </div>
      <span className="load-state__title">{data[state].title}</span>
      <p className="load-state__text">{data[state].text}</p>
      {state === 'success' && (
        <Button className="load-state__button" onClick={onClick}>Продолжить</Button>
      )}
    </div>
  )
}

export default LoadState