import { createSelector } from 'reselect';
import { RootState, Selector } from '../rootTypes';
import { CitiesType, CityItemType, InitialStateType } from './types';

export const getCitiesModule = (state: RootState): InitialStateType => 
  state.RootReducer.cities;

export const getCities: Selector<CitiesType> = 
  createSelector(
    getCitiesModule, 
    cities => cities.cities
  );

export const getIsLoadingCities: Selector<boolean> = 
  createSelector(
    getCitiesModule, 
    cities => cities.isLoadingCities
  );

export const getActiveCity: Selector<CityItemType> = 
  createSelector(
    getCitiesModule, 
    cities => cities.activeCity
  );