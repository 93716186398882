import { combineReducers } from '@reduxjs/toolkit';
import regions from './regions';
import cities from './cities';
import bids from './bids';

export const RootReducer = combineReducers({
  cities,
  regions,
  bids
});

const createRootReducer = () => RootReducer;

export default createRootReducer;
