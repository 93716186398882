import React from 'react'
import Button from '../../../../components/Button'

type FormActionsType = {
  step: number;
  stepMax: number;
  buttonText: string;
  onClick: () => void;
  disabledButton?: boolean
}

const FormActions: React.FC<FormActionsType> = ({ 
  step, stepMax, buttonText, onClick, disabledButton = false
}) => {
  return (
    <div className="warehouse-bid__actions">
      <div className="warehouse-bid__steps">
        <p>Шаг {step} из {stepMax}</p>
        <span className={`warehouse-bid__steps-progress w-${Math.round(step / stepMax * 100)}`} />
      </div>
      <Button className="warehouse-bid__button" onClick={onClick} disabled={disabledButton}>{buttonText}</Button>
    </div>
  )
}

export default FormActions