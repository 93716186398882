import React, { useEffect, useState } from 'react'
import useLocalStorage from '../../hooks/useLocalStorage';
import Button from '../../components/Button';
import SelectRegion from '../../components/SelectRegion';
import './ModalSelectRegion.scss';
import Modal from '../Modal';

type ModalSelectRegionType = {
  activeCityName: string;
  isLoading: boolean;
  fetchActiveRegion: () => void;
};

const ModalSelectRegion: React.FC<ModalSelectRegionType> = ({
  activeCityName, 
  isLoading, 
  fetchActiveRegion, 
}) => {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const { value: localRegion } = useLocalStorage<string>('ya_ywarehouse_region');

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [isSelectRegion, setIsSelectRegion] = useState<boolean>(false);
  const clickHandler = () => setIsSelectRegion(true);

  const confirmRegion = () => {
    closeModal()
    fetchActiveRegion()
  } 

  useEffect(() => {
    if (!localRegion) {
      openModal()
    }
  }, [])
  
  return (
    <Modal isOpen={modalIsOpen} onClose={closeModal}>
      <div className="main__block main-block">
        <h2 className="main-block__title">Склады для сортировки, перегруза и хранения вашего груза</h2>
        <p className="main-block__subtitle">
          {isSelectRegion ? 'Укажите свой регион' : `Ваш регион ${activeCityName}?`}
        </p>
        {isSelectRegion ? (
          <div className="main-block__select">
            <SelectRegion onFetchRegion={closeModal} />
          </div>
        ) : (
          <div className="main-block__actions">
            <Button 
              variant="outline"
              onClick={clickHandler}
              size="lg"
            >
              Выбрать другой
            </Button>
            <Button 
              onClick={confirmRegion}
              size="lg"
              disabled={isLoading}
            >
              Верно
            </Button>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ModalSelectRegion;