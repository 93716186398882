import React from 'react';
import classnames from 'classnames';
import './Button.scss';

type ButtonType = {
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  variant?: 'gray' | 'blue' | 'outline' | 'link' | 'black';
  size?: 'lg';
  disabled?: boolean;
  active?: boolean;
  className?: string;
  tag?: 'a' | 'button';
  href?: string;
  isTargetBlank?: boolean;
};

const Button: React.FC<ButtonType> = ({ type = 'button', children, onClick, variant, size, disabled = false, active = false, className, tag = 'button', href, isTargetBlank = false }) => {
  const getButtonClass = (): string => classnames('btn', className, { 
    'btn--outline': variant === 'outline',
    'btn--gray': variant === 'gray',
    'btn--blue': variant === 'blue',
    'btn--black': variant === 'black',
    'btn--link': variant === 'link',
    'btn--lg': size === 'lg',
    'active': active
  });

  const Tag = tag;
  let onlyTagProps: {} = { type };

  if (tag === 'a') {
    onlyTagProps = { 
      href,
      ...isTargetBlank && { target: '_blank' }
    };
  }


  return (
    <Tag 
      onClick={onClick}
      className={getButtonClass()}
      disabled={disabled}
      {...onlyTagProps}
    >
      {children}
    </Tag>
  );
};

export default Button;
