import { AxiosResponse } from 'axios';
import { axiosGet } from '../../helpers/api';
import { StaticDataType, RegionType } from './types';

export const apiFetchRegion = async (id: number) => {
  try {
    const res: AxiosResponse<RegionType> = await axiosGet(`v1/cities/${id}/locations`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiFetchStaticData = async () => {
  try {
    const res: AxiosResponse<StaticDataType> = await axiosGet('v1/static_data');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};