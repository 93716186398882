import React from 'react'

type FormGroupType = {
  title: string
}

const FormGroup: React.FC<FormGroupType> = ({ title, children }) => {
  return (
    <div className="warehouse-bid__group">
      <span className="warehouse-bid__group-title">{title}</span>
      <div className="warehouse-bid__group-content">
        {children}
      </div>
    </div>
  )
}

export default FormGroup
