import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import 'moment/locale/ru';

import Button from '../../../components/Button';
import ButtonGroup from '../../../components/ButtonGroup';
import MapBlock from '../../../components/MapBlock';
import { MapItemType } from '../../../store/regions';

import { ReactComponent as TripSvg } from './trip.svg';
import { ReactComponent as PhoneSvg } from './phone.svg';
import { ReactComponent as AddressSvg } from './address.svg';
import { ReactComponent as TimeSvg } from './time.svg';
import { ReactComponent as SiteSvg } from './site.svg';
import { ReactComponent as EmailSvg } from './email.svg';
import { ReactComponent as PaletSvg } from './palet.svg';
import './MapPoint.scss';
import { formatPrice } from '../../../helpers/price';
import { declOfNum } from '../../../helpers/declOfNum';
import MapWarehouseBid from '../MapWarehouseBid';

type MapPointType = {
  item: MapItemType | null;
  active: boolean;
  onClose: () => void;
  geolocationCoordinate: number[]
};

const palletWords = ['паллетоместо', 'паллетомест', 'паллетомест']

const MapPoint: React.FC<MapPointType> = ({ 
  item, active, onClose, geolocationCoordinate
}) => {
  const descrRef = useRef<HTMLSpanElement>(null);
  const priceRef = useRef<HTMLSpanElement>(null);
  const [isShowTime, setIsShowTime] = useState<boolean>(true);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [swipeHeight, setSwipeHeight] = useState<number>(0);

  const toggleIsShowTime = (): void => {
    setIsShowTime(!isShowTime);
  };

  const getTimeButtonClass = (): string => {
    const defaultClass = 'map-point__item';
    const withArrowClass = `${defaultClass}--with-arrow`;
    const activeClass = `${defaultClass}--active`;
    
    return classnames(defaultClass, withArrowClass, {
      [activeClass]: isShowTime
    });
  };

  const getRouteLink = (): string => {
    if (!item) {
      return '#';
    }
    
    return `https://yandex.ru/maps?rtext=${geolocationCoordinate.join(',')}~${item.coordinate.join(',')}`;
  };

  const getNextOpenTime = (time: string = ''): string => {
    moment.locale('ru');
    const date = moment(time);
    const diffDate = date.diff(moment(), 'days');
    const openTime = date.format('HH:mm');
    return diffDate > 1 ? `в ${date.format('dd')} в ${openTime}` : `завтра в ${openTime}`;
  };

  const closeHandler = (): void => {
    if (isOpenModal) return
    onClose();
    setSwipeHeight(0);
  };

  useEffect(() => {
    if (!priceRef.current || !descrRef.current || window.innerWidth > 768) return;
    setSwipeHeight(descrRef.current.offsetHeight + 90 + priceRef.current.offsetHeight);
  }, [item, priceRef.current]);
  
  return (
    <MapBlock 
      title={item ? item.title : ''}
      active={active}
      onClose={closeHandler}
      swipeHeight={swipeHeight}
    >
      {item && (
        <div className="map-point">
          <span className="map-pint__costPerDay" ref={priceRef}>
            Стоимость паллетоместа / день • {formatPrice(item.cost_per_day)}
          </span>
          <span className="map-pint__descr" ref={descrRef}>{item.description}</span>
          <div className="map-point__action">
            <Button
              className="map-point__trip"
              variant="outline"
              tag="a"
              href={getRouteLink()}
              isTargetBlank={true}
            >
              <TripSvg /> Маршрут
            </Button>
            <Button variant="black" className="map-point__education" onClick={() => setIsOpenModal(true)}>
              Забронировать
            </Button>
            <MapWarehouseBid 
              location={item.id} 
              isOpenModal={isOpenModal} 
              onCloseModal={() => setIsOpenModal(false)} 
            />
          </div>
          <div className="map-point__buttons">
            <ButtonGroup disabled={true}>
              {item.equipments.map(equipment => (
                <Button variant="gray" key={equipment}>
                  {equipment}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div className="map-point__list">
            {item.pallet_spaces && (
              <div className="map-point__item">
                <div className="map-point__item-icon">
                  <PaletSvg />
                </div>
                <div className="map-point__item-info">
                  <span className="map-point__item-title">Места</span>
                  <span>Доступно {item.pallet_spaces} {declOfNum(item.pallet_spaces, palletWords)} для бронирования</span>
                </div>
              </div>
            )}
              <div className="map-point__item">
                <div className="map-point__item-icon">
                  <PhoneSvg />
                </div>
                <div className="map-point__item-info">
                  <span className="map-point__item-title">Контакты</span>
                  {item.phones.map(phoneItem => (
                    <a href={`tel:${phoneItem}`} key={phoneItem}>{phoneItem}</a>
                  ))}
                  {item.company && (
                    <p>
                      {item.company.name}, ИНН {item.company.inn},<br/>{item.company.info}
                    </p>
                  )}
                  {item.email && (
                    <a href={`mailto:${item.email}`}>{item.email}</a>
                  )}
                </div>
              </div>
            {item.website && (
              <div className="map-point__item">
                <div className="map-point__item-icon">
                  <SiteSvg />
                </div>
                <div className="map-point__item-info">
                  <Button 
                    tag="a"
                    href={item.website}
                    variant="link"
                    isTargetBlank={true}
                  >
                    {item.website}
                  </Button>
                </div>
              </div>
            )}
            {item.address && (
              <div className="map-point__item">
                <div className="map-point__item-icon">
                  <AddressSvg />
                </div>
                <div className="map-point__item-info">
                  <span>{item.address}</span>
                </div>
              </div>
            )}
            <button 
              type="button"
              className={getTimeButtonClass()}
              onClick={toggleIsShowTime}
            >
              <div className="map-point__item-icon">
                <TimeSvg />
              </div>
              <div className="map-point__item-info">
                {item.is_closing ? (
                  <span>
                    Закрыто. Откроется {getNextOpenTime(item.next_open_time)}
                  </span>
                ) : (
                  <span>Открыто до {item.close_time!.substr(0, 5)}</span>
                )}
              </div>
            </button>
            {isShowTime && (
              <ul className="map-point__time-list">
                {item.working_hours.map(day => (
                  <li className="map-point__time-item" key={day.day}>
                    <span>{day.day}</span>
                    <span>{day.time}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="map-point__footer">
            <span className="map-point__footer-copy">© 2001–{new Date().getFullYear()}</span>
            <p>
              &nsbp;
            </p>
          </div>
        </div>
      )}
    </MapBlock>
  );
};

export default MapPoint;
