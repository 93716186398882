import React from 'react';
import { useSelector } from 'react-redux';
import { getLoadState, LoadStateType } from '../../../store/bids';
import { setLoadState } from '../../../store/bids/reducer';
import { useAppDispatch } from '../../../store/rootTypes';
import MapWarehouseBidView from './MapWarehouseBid';

export type MapWarehouseBidTypeBase = {
  location: number;
  isOpenModal: boolean;
  onCloseModal: () => void
}

const MapWarehouseBid: React.FC<MapWarehouseBidTypeBase> = ({ 
  location, isOpenModal, onCloseModal 
}) => {
  const dispatch = useAppDispatch();
  const loadState = useSelector(getLoadState);

  const setLoadStateHandler = 
    (loadState: LoadStateType) => dispatch(setLoadState(loadState));

  return (
    <MapWarehouseBidView
      loadState={loadState}
      setLoadState={setLoadStateHandler}
      location={location}
      isOpenModal={isOpenModal}
      onCloseModal={onCloseModal}
    />
  );
};

export default MapWarehouseBid;