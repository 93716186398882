import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal';
import { ReactComponent as Clear } from './clear.svg';
import './Modal.scss'

ReactModal.setAppElement('#root');

type ModalType = {
  isOpen: boolean,
  onClose: () => void;
  className?: string;
}

const Modal: React.FC<ModalType> = ({ children, onClose, isOpen, className = '' }) => {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);

  const closeModal = () => {
    setIsOpen(false)
    onClose()
  };

  useEffect(() => {
    setIsOpen(isOpen)
  }, [isOpen])

  return (
    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      overlayClassName="app-modal-overlay"
      className={`app-modal-content ${className}`}
    >
      <button
        type="button"
        className="app-modal-content__clear"
        aria-label="Закрыть модальное окно"
        onClick={closeModal}
      >
        <Clear />
      </button>
      {children}
    </ReactModal>
  )
}

export default Modal