import React, { useEffect } from 'react';
import AppView from './App';
import { fetchStaticData, fetchRegion } from '../store/regions';
import { useAppDispatch } from '../store/rootTypes';
import useLocalStorage from '../hooks/useLocalStorage';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { value } = useLocalStorage<number>('ya_ywarehouse_region');

  useEffect(() => {
    if (value) dispatch(fetchRegion(value));
    dispatch(fetchStaticData())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <AppView />
  );
};

export default App;