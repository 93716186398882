import axios from 'axios';
// import { getCookie } from './cookie';

const axiosCreate = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? `${process.env.API_DEV_HOST || 'https://ywarehouse-dev.inno.co'}:${
        process.env.API_DEV_PORT || ''
      }/api/`
      : `${process.env.API_PROD_URL || ''}/api/`,
  responseType: 'json',
  withCredentials: true,
});

export const axiosGet = async (url: string, cookie?: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const headers: any = {};
  if (cookie) {
    headers.Cookie = cookie;
  }
  try {
    const res = await axiosCreate.get(url, { headers });
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const axiosPost = async (url: string, data: unknown) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const headers: any = {};
  // const csrftoken: string = getCookie('csrftoken');
  // if (csrftoken && csrftoken.length > 0) {
  //   headers['X-CSRFToken'] = csrftoken;
  // }

  if (process.env.NODE_ENV === 'development') {
    headers['X-CSRFToken'] = 'Q4rTyP5sfamPX2e8Xc9LDOHsMNXxUmi6snbxdf0MZOITK2oV8pThxcqo6VBshnir';
  }

  try {
    const res = await axiosCreate.post(url, data, { 
      headers, 
      withCredentials: true 
    });
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default axiosCreate;
