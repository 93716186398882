import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterType, OrganizationType, ProductCategory, RegionType, StaticDataType } from './types';

export const initialState = {
  region: {} as RegionType,
  isLoading: false,
  activeFilter: '',
  filtersData: [] as FilterType[],
  productCategories: [] as ProductCategory[],
  organizationTypes: [] as OrganizationType[],
};
 
const cities = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    setRegion: (state, action: PayloadAction<RegionType>) => {
      state.region = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setActiveFilter: (state, action: PayloadAction<string>) => {
      state.activeFilter = action.payload;
    },
    setStaticData: (state, action: PayloadAction<StaticDataType>) => {
      state.filtersData = action.payload.equipments;
      state.productCategories = action.payload.product_categories
      state.organizationTypes = action.payload.organization_types
    }
  },
});

export const { reducer, actions } = cities;
export const { setRegion, setIsLoading, setActiveFilter, setStaticData } = actions;
export default reducer;