import { ThunkType } from './types';
import { apiFetchStaticData, apiFetchRegion } from './api';
import { setStaticData, setIsLoading, setRegion } from './reducer';

export const fetchRegion = (id: number): ThunkType => async (dispatch) => {
  dispatch(setIsLoading(true));

  await apiFetchRegion(id).then((res) => {
    dispatch(setRegion(res.data));
  }).finally(() => {
    dispatch(setIsLoading(false));
  });
};

export const fetchStaticData = (): ThunkType => async (dispatch) => {
  await apiFetchStaticData().then((res) => {
    dispatch(setStaticData(res.data));
  }).finally(() => {
    dispatch(setIsLoading(false));
  });
};