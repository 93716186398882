import { createSelector } from 'reselect';
import { FilterType } from '.';
import { RootState, Selector } from '../rootTypes';
import { InitialStateType, MapItemType, OrganizationType, ProductCategory, RegionType } from './types';

export const getRegionsModule = (state: RootState): InitialStateType => 
  state.RootReducer.regions;

export const getRegion: Selector<RegionType> = 
  createSelector(
    getRegionsModule, 
    regions => regions.region
  );

export const getIsLoading: Selector<boolean> = 
  createSelector(
    getRegionsModule, 
    regions => regions.isLoading
  );

export const getActiveFilter: Selector<string> = 
  createSelector(
    getRegionsModule, 
    regions => regions.activeFilter
  );

export const getFiltersData: Selector<FilterType[]> = 
  createSelector(
    getRegionsModule, 
    regions => regions.filtersData
  );

export const getProductCategories: Selector<ProductCategory[]> = 
  createSelector(
    getRegionsModule, 
    regions => regions.productCategories
  );

export const getOrganizationTypes: Selector<OrganizationType[]> = 
  createSelector(
    getRegionsModule, 
    regions => regions.organizationTypes
  );

export const getRegionItems: Selector<MapItemType[]> = 
  createSelector(
    [getRegion, getActiveFilter, getFiltersData],
    (region, activeFilter, filtersData) => {
      if (activeFilter.length === 0) return region.items;
      const activeFilterItems = activeFilter.split(',').map(slug => filtersData.find(item => item.slug === slug)?.name || '');
      return region.items.filter(item => {
        const notHaveEquip = activeFilterItems.filter(
          filterItem => !item.equipments.includes(filterItem)
        );
        
        return notHaveEquip.length === 0;
      });
    }
  );