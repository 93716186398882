import React from 'react';
import { useSelector } from 'react-redux';
import { getRegion } from '../../store/regions';
import MapPageView from './MapPage';

const MapPage: React.FC = () => {
  const region = useSelector(getRegion);

  return (
    <MapPageView
      region={region}
    />
  );
};

export default MapPage;