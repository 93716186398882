import React from 'react';
import ModalSelectRegion from '../../components/ModalSelectRegion';
import { ReactComponent as Box } from './box.svg';
import { ReactComponent as DeliveryTruck } from './delivery-truck.svg';
import { ReactComponent as Warehouse } from './warehouse.svg';
import './MainPage.scss';

const MainPage: React.FC = () => {
  return (
    <div className="main">
      <section className="main__hero">
        <div className="container">
          <h1 className="main__title">Склады</h1>
          <p className="main__descr">— это возможность найти место для сортировки, перегруза и хранения сроком от 1-ого до 4-х месяцев</p>
        </div>
      </section>
      <footer className="main__footer">
        <div className="container">
          <h2 className="main__footer-title">Почему это удобно</h2>
          <div className="main__footer-list">
            <div className="main__footer-item">
              <Warehouse />
              <p>Вся информация о складах в&nbsp;одном месте</p>
            </div>
            <div className="main__footer-item">
              <DeliveryTruck />
              <p>Не нужно тратить время<br/>на поиск подрядчиков</p>
            </div>
            <div className="main__footer-item">
              <Box />
              <p>Быстрое подтверждение услуг для размещения на выбранном складе</p>
            </div>
          </div>
        </div>
        <div className="copyright">©</div>
      </footer>
      <ModalSelectRegion />
    </div>
  );
};

export default MainPage;

