import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useLocalStorage from '../../hooks/useLocalStorage';
import { fetchCity, getActiveCity } from '../../store/cities';
import { fetchRegion, getIsLoading, getRegion } from '../../store/regions';
import { useAppDispatch } from '../../store/rootTypes';
import ModalSelectRegionView from './ModalSelectRegion';


const ModalSelectRegion: React.FC = () => {
  const dispatch = useAppDispatch();
  const region = useSelector(getRegion);
  const activeCity = useSelector(getActiveCity);
  const isLoadingRegion = useSelector(getIsLoading);
  const { setValue } = useLocalStorage<number>('ya_ywarehouse_region');

  const fetchCityHandler = () => dispatch(fetchCity());
  const fetchRegionHandler = () => {
    if (activeCity.id !== region.id) {
      dispatch(fetchRegion(activeCity.id));
      setValue(activeCity.id);
    }
  }

  useEffect(() => {
    fetchCityHandler();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalSelectRegionView 
      activeCityName={activeCity.name || ''}
      isLoading={!activeCity.id || isLoadingRegion}
      fetchActiveRegion={fetchRegionHandler}
    />
  );
};

export default ModalSelectRegion;