import React from 'react'

type FormBlockType = {
  label?: string;
  errorText?: string
}

const FormBlock: React.FC<FormBlockType> = ({ label, errorText, children }) => {
  return (
    <div className={`warehouse-bid__group-block ${errorText ? 'error' : ''}`}>
      {label && (
        <span className="warehouse-bid__group-label">{label}</span>
      )}
      {children}
      {errorText && (
        <span className="warehouse-bid__group-error">{errorText}</span>
      )}
    </div>
  )
}

export default FormBlock