import { ProposalAddRequestType, ProposalCompanyAddRequestType, ThunkType } from './types';
import { apiPostProposalAdd, apiPostProposalCompanyAdd } from './api';
import { setLoadingPPC, setLoadState, setProporsal } from './reducer';

export const postProposalAdd = (request: ProposalAddRequestType): ThunkType => async (dispatch) => {
  dispatch(setLoadState('loading'))
  await apiPostProposalAdd(request).then((res) => {
    dispatch(setProporsal(res.data));
    dispatch(setLoadState('success'))
  }).catch((e) => {
    if (e.response.data?.pallet_spaces?.find(error => error.code === 'oversize')) {
      dispatch(setLoadState('empty'))
    }
  });
};

export const postProposalCompanyAdd = (request: ProposalCompanyAddRequestType): ThunkType => async (dispatch) => {
  dispatch(setLoadingPPC(true))
  await apiPostProposalCompanyAdd(request).then((res) => {
    dispatch(setLoadState('booked'))
  }).finally(() => {
    dispatch(setLoadingPPC(false))
  });
};